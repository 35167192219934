var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"breadcrumb_header"},[_vm._m(0),_c('div',[_c('button',{staticClass:"open_sidebar me-2",on:{"click":_vm.toggleSidebar}},[_c('span',{staticClass:"material-symbols-rounded icon"},[_vm._v("keyboard_double_arrow_left")]),_c('p',[_vm._v("Open Sidebar")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 mt-3"},[_c('div',{staticClass:"d-flex justify-content-start cursor-pointer back",on:{"click":_vm.goBack}},[_c('span',{staticClass:"material-symbols-rounded",staticStyle:{"margin":"0"}},[_vm._v("arrow_back")]),_c('span',{staticStyle:{"margin":"0 8px"}},[_vm._v("Back")])])]),_c('div',{staticClass:"col-md-10 mt-5"},[_c('div',{staticClass:"left-container"},[_vm._m(1),_c('section',{staticClass:"upload-file"},[_c('h6',[_vm._v("Upload Case Files")]),_c('span',{staticClass:"sub-title mb-2"},[_vm._v(" Securely upload your contracts to begin the review. ")]),_c('UploadFiles',{attrs:{"not-send":"","only-file":""},on:{"get-file":_vm.getFile,"get-jurisdiction":_vm.getJurisdiction}},[_vm._v("No files uploaded yet.")]),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"me-2",class:{
                'button-generate': _vm.isAllFieldValid,
                'button-generate-muted': !_vm.isAllFieldValid,
              },staticStyle:{"display":"flex","align-items":"center","gap":"4px","cursor":"pointer"},attrs:{"disabled":!_vm.isAllFieldValid}},[_c('span',{staticClass:"material-symbols-rounded icon"},[_vm._v("upload")]),_vm._v(" Create a MS Word ")]),_c('div',{class:{
                'button-generate': _vm.isAllFieldValid,
                'button-generate-muted': !_vm.isAllFieldValid,
              },staticStyle:{"display":"flex","align-items":"center","gap":"4px","cursor":"pointer"},attrs:{"disabled":!_vm.isAllFieldValid},on:{"click":_vm.uploadFile}},[_c('span',{staticClass:"material-symbols-rounded icon"},[_vm._v("send")]),_vm._v(" Generate Contract ")])])],1)])]),_c('div',{staticClass:"col-md-1"})]),(_vm.sidebarCollapsed)?_c('sidebar',{directives:[{name:"clickaway",rawName:"v-clickaway",value:(_vm.toggleSidebar),expression:"toggleSidebar"}],on:{"toggle-sidebar":_vm.toggleSidebar}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"breadcrumb ms-2"},[_c('h4',{staticStyle:{"margin":"0","padding-left":"10px"}},[_vm._v("NeXa")]),_c('span',{staticClass:"divider"}),_c('span',[_vm._v("Use Case / Contract Due Diligence")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tabs"},[_c('h6',{staticClass:"tabs-title"},[_vm._v("Contract Due Diligence")]),_c('p',{staticClass:"tabs-description"},[_vm._v(" Upload your contracts for a comprehensive AI-powered review. ")])])
}]

export { render, staticRenderFns }